$hyoo_lingua_app $mol_page
	title \Lingua Franca
	plugins /
		<= Theme $mol_theme_auto
	head /
		<= Native_lang $mol_select
			hint @ \Native Language
			value? <=> native_lang? \ru
			dictionary <= langs *
		<= Foreign_translate $mol_button_minor
			hint @ \Translate to Native
			click? <=> foreign_translate? null
			event_activate? => foreign_translate_activate?
			sub /
				<= Foreign_translate_icon $mol_icon_transfer_left
		<= Native_translate $mol_button_minor
			hint @ \Translate to Foreign
			click? <=> native_translate? null
			event_activate? => native_translate_activate?
			sub /
				<= Native_translate_icon $mol_icon_transfer_right
		<= Foreign_lang $mol_select
			hint @ \Foreign Language
			value? <=> foreign_lang? \en
			dictionary <= langs *
	foot /
		<= Source $mol_link_source uri \https://github.com/hyoo-ru/lingua.hyoo.ru
		<= Title
		<= Lights $mol_lights_toggle
	Body $mol_book2
		bring <= bring
		Placeholder null
		pages /
			<= Native_pane $mol_scroll sub /
				<= Native_text $mol_textarea
					hint @ \native language 
					value? <=> native_text? \
					submit? <=> native_translate_activate?
			<= Foreign_pane $mol_scroll sub /
				<= Foreign_text $mol_textarea
					hint @ \foreign language text
					value? <=> foreign_text? \
					bring => bring
					submit? <=> foreign_translate_activate?
